<template>
  <div>
    <page-title />

    <b-table
      ref="table"
      :items="itemsProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      api-url="/balance"
      stacked="sm"
      show-empty
    />
    <div>
      <table-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
      <table-limit
        v-model="perPage"
        :total-rows="totalRows"
      />
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import table from '@/mixins/table'
import TablePagination from '@/components/TablePagination'
import TableLimit from '@/components/TableLimit'

export default {
  name: 'Balance',
  components: { PageTitle, TablePagination, TableLimit },
  mixins: [table],
  data () {
    return {
      fields: [
        { key: 'alias' },
        { key: 'operation' },
        { key: 'amount' },
        { key: 'before' },
        { key: 'after' }
      ]
    }
  }
}
</script>
